//fontawesome
(function () {
  var css = document.createElement("link");
  css.href = "https://use.fontawesome.com/releases/v5.1.0/css/all.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(css);
})();

//YakuHanJP
(function () {
  var css = document.createElement("link");
  css.href =
    "https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/css/yakuhanjp.min.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  document.getElementsByTagName("head")[0].appendChild(css);
})();


import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';


//スムーススクロール
import SmoothScroll from 'smooth-scroll'
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  speed: 100
});

//ローデイング
window.onload = function() {
  const spinner = document.getElementById('loading');
  if (spinner !== null) {
    spinner.classList.add('is-loaded');
  }
}

//ハンバーガーボタン
// (function () {
//   $('#js-buttonHamburger').click(function () {
//     $('body').toggleClass('is-drawerActive');

//     if ($(this).attr('aria-expanded') == 'false') {
//       $(this).attr('aria-expanded', true);
//     } else {
//       $(this).attr('aria-expanded', false);
//     }
//   });
// }) ();

// function toggleNav() {
//   var body = document.body;
//   var hamburger = document.getElementById('js-buttonHamburger');
//   // var blackBg = document.getElementById('js-black-bg');

//   hamburger.addEventListener('click', function() {
//     body.classList.toggle('is-drawerActive');
//     var status= hamburger.getAttribute('aria-expanded');
//     if(status==true){
//       hamburger.removeAttribute('aria-expanded');
//       console.log('true');
//     }else{
//       hamburger.setAttribute('aria-expanded',true);
//       console.log('flase');
//     }
//   });
//   // blackBg.addEventListener('click', function() {
//   //   body.classList.remove('nav-open');
//   // });
// }
// toggleNav();


import MicroModal from 'microModal'

window.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  const btn = document.querySelector('#js-buttonHamburger');

  anchorLinksArr.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetoffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;

      if (btn.ariaExpanded == 'true') {
        btn.ariaExpanded = false;
      }
      // micromodalによるclose
      anchorLinksArr.ariaExpanded = false;
      //MicroModal.close('modal-1');

      window.scrollTo({
        top: targetoffsetTop,
        behavior: 'smooth',
      });
    });
  });

  let scrollPos;

  btn.addEventListener('click', function () {
    const bodyElement = document.querySelector('body');
    if (btn.ariaExpanded == 'false') {
      btn.ariaExpanded = true;

      scrollPos = window.scrollY;
      bodyElement.classList.add('open');
      bodyElement.style.top = scrollPos;

      MicroModal.show('modal-1', {
        disableScroll: true,
        awaitOpenAnimation: true,
      });
    } else {
      btn.ariaExpanded = false;
      bodyElement.classList.remove('open');
      window.scrollTo(0, scrollPos);

      MicroModal.close('modal-1', {
        awaitCloseAnimation: true,
      });
    }
  });
});



//スライダー
import Swiper from 'swiper';
var slider_other = new Swiper('.t-other__list .swiper-container', {
  loop: true,
  allowTouchMove: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView:3,
  breakpoints: {
    768: {
      slidesPerView: 1,
      allowTouchMove: true,
    },
  }
});
var slider_buyingrecord = new Swiper('.t-buyingrecord__list .swiper-container', {
  loop: true,
  allowTouchMove: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView:3,
  breakpoints: {
    768: {
      slidesPerView: 1,
      allowTouchMove: true,
    },
  }
});

var slider_other = new Swiper('.bt-pickup__list .swiper-container', {
  loop: true,
  allowTouchMove: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
      el: '.swiper-pagination',
      clickable: true //この行を追記する
  },
});


var slider_other = new Swiper('.g-achievement__slider .swiper-container', {
  loop: true,
  allowTouchMove: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

//アコーディオン
// document.addEventListener("DOMContentLoaded",() => {
//   const title = document.querySelectorAll('.js_accordion_head');

//   for (let i = 0; i < title.length; i++){
//     let titleEach = title[i];
//     let content = titleEach.nextElementSibling;
//     titleEach.addEventListener('click', () => {
//       titleEach.classList.toggle('is-active');
//       content.classList.toggle('is-open');
//     });
//   }

// });


/* =================================================== */
// slideUp, slideDown, slideToggle関数を定義
/* =================================================== */

// 要素をスライドしながら非表示にする関数(jQueryのslideUpと同じ)
const slideUp = (el, duration = 300) => {
  el.style.height = el.offsetHeight + "px";
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = "none";
    el.style.removeProperty("height");
    el.style.removeProperty("padding-top");
    el.style.removeProperty("padding-bottom");
    el.style.removeProperty("margin-top");
    el.style.removeProperty("margin-bottom");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
    el.classList.remove("is-open");
  }, duration);
};

// 要素をスライドしながら表示する関数(jQueryのslideDownと同じ)
const slideDown = (el, duration = 300) => {
  el.classList.add("is-open");
  el.style.removeProperty("display");
  let display = window.getComputedStyle(el).display;
  if (display === "none") {
    display = "block";
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = "hidden";
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = "height, margin, padding";
  el.style.transitionDuration = duration + "ms";
  el.style.transitionTimingFunction = "ease";
  el.style.height = height + "px";
  el.style.removeProperty("padding-top");
  el.style.removeProperty("padding-bottom");
  el.style.removeProperty("margin-top");
  el.style.removeProperty("margin-bottom");
  setTimeout(() => {
    el.style.removeProperty("height");
    el.style.removeProperty("overflow");
    el.style.removeProperty("transition-duration");
    el.style.removeProperty("transition-property");
    el.style.removeProperty("transition-timing-function");
  }, duration);
};

// 要素をスライドしながら交互に表示/非表示にする関数(jQueryのslideToggleと同じ)
const slideToggle = (el, duration = 300) => {
  if (window.getComputedStyle(el).display === "none") {
    return slideDown(el, duration);
  } else {
    return slideUp(el, duration);
  }
};

/* =================================================== */
// DOM操作
/* =================================================== */

// アコーディオンを全て取得
const accordions = document.querySelectorAll(".js-accordion");
// 取得したアコーディオンをArrayに変換(IE対策)
const accordionsArr = Array.prototype.slice.call(accordions);

accordionsArr.forEach((accordion) => {
  // Triggerを全て取得
  const accordionTriggers = accordion.querySelectorAll(".js-accordion-trigger");
  // TriggerをArrayに変換(IE対策)
  const accordionTriggersArr = Array.prototype.slice.call(accordionTriggers);

  accordionTriggersArr.forEach((trigger) => {
    // Triggerにクリックイベントを付与
    trigger.addEventListener("click", () => {
      // '.is-active'クラスを付与or削除
      trigger.parentNode.classList.toggle("is-active");
      // 開閉させる要素を取得
      const content = trigger.parentNode.querySelector(".js-accordion-content");
      // 要素を展開or閉じる
      slideToggle(content);
    });
  });
});

// よくある質問ページ　タブ切り替え
$('.js-tabbtn').on('click', function() {
  $('.js-tabdata').hide();
  $('#' + $(this).attr('href')).show();
  $('.js-tabselected').removeClass('js-tabselected');
  $(this).addClass('js-tabselected');
  return false;
});



const openCategory = $('.c-highbrand .c-category');
const moreBtn_a = openCategory.next().find('.-down');
const outerHeight = openCategory.height();
const innerHeight = openCategory.children().height()+30;
$(moreBtn_a).on('click', function() {
  //const openCategory = $(this).parents('.c-txtbtn').prev('.c-category');
  $(this).toggleClass('is-open');
  openCategory.toggleClass('is-open');
  if( $(this).hasClass('is-open')){
    openCategory.animate({height: innerHeight}, 150);
  }else{
    openCategory.animate({height: outerHeight}, 150);
  }
  return false;
});

const openCategory2 = $('.c-buyinggenre .c-category');
const moreBtn_a2 = openCategory2.next().find('.-down');
const outerHeight2 = openCategory2.height();
const innerHeight2 = openCategory2.children().height()+30;
$(moreBtn_a2).on('click', function() {
  //const openCategory = $(this).parents('.c-txtbtn').prev('.c-category');
  $(this).toggleClass('is-open');
  openCategory2.toggleClass('is-open');
  if( $(this).hasClass('is-open')){
    openCategory2.animate({height: innerHeight2}, 150);
  }else{
    openCategory2.animate({height: outerHeight2}, 150);
  }
  return false;
});


//.c-highbrand .c-category, .c-buyinggenre .c-category
// const moreBtn = OmittedCategory.next();
// console.log(moreBtn);


